import React from 'react'
import { graphql, Link } from 'gatsby'
import { map as _map, truncate } from 'lodash'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'
import { v4 } from 'uuid'
import { globalHistory } from "@reach/router";
import Content, { HTMLContent } from '../components/Content'
import { FluxibleIconBlock } from '../components/FluxibleIcon'
import Layout from '../components/layout'
import { getImage, getSocialMedia } from '../util'
import { formatDate, formatDuration, TZ } from '../util/events'
import moment from 'moment-timezone'

const SpeakerTemplate = ({
  content,
  contentComponent,
  title,
  position,
  photo,
  books,
  socialMedia,
  events,
  editionSlug
}) => {
  const SpeakerContent = contentComponent || Content

  const getBooks = () => {
    if (!books || !books.length) return null

    const linksList = books.map(book => {
      return (
        <li key={v4()}>
          <FluxibleIconBlock
            key={v4()}
            icon="book"
            text={book.title}
            link={book.url}
          />
        </li>
      )
    })

    return (
      <div className="books-section">
        <h5>Author of</h5>
        <ul className="books no-bullet">{linksList}</ul>
      </div>
    );
  }

  const getEvents = () => {
    if (!events) return null

    const eList = _map(events, event => {
      return (
        <div key={v4()}>
          <div className="event-title">
            <Link id={event.fields.slug} to={'/' + event.fields.slug}>
              {event.name}
            </Link>
          </div>
          <div className="fluxible_date_header">
            {formatDate(event.startDate)}<br />
            {formatDuration(event.startDate, event.endDate)} {" "} {moment.tz(TZ).zoneAbbr()}
          </div>
          {event.location &&
            event.location.name && (
              <div className="event_location">
                <Link to={event.location.fields.slug}>
                  <FluxibleIconBlock
                    icon="building"
                    text={event.location.name}
                    size="sm"
                  /></Link>
              </div>
            )}
          {event.eventType && event.eventType.visible ?
            (<span className="program fluxible_event_type">
              {/*This regular expression removes times from eventType names
                     Ex. "Fluxible Conference 40-minute presentation" -> "Fluxible Conference presentation"*/}
              {event.eventType.name.replace(/(\d*-\w*)\s/g, "")}
            </span>)
            : ''}
        </div>
      )
    })

    if (!eList.length) return null

    return (
      <div className="events-section">
        <h4>Sessions with {title}</h4>
        <div>
          {eList}
        </div>
      </div>
    )
  }

  return (
    <section className="section speaker-info-container">
      <aside className="speaker-card">
        <div>
          {getImage(photo, title, "speaker-photo")}
        </div>

        <div className="card-section">
          <div className="content">
            {getSocialMedia(socialMedia, true, title)}
          </div>
        </div>
      </aside>

      <div className="speaker-title">
        <h1 className="speaker-heading">
          {title}
        </h1>
        <p className="speaker-position">{position}</p>
      </div>

      <div className="speaker-content">
        <SpeakerContent content={content} />
        {getBooks()}
        {getEvents()}
        <Link
          className="return-link"
          to={editionSlug !== undefined ? `/${editionSlug}/speakers` : '/speakers'}
          aria-label="Return to speakers list"
        >
          <i className="fa fa-chevron-left left-chevron" aria-hidden="true" />
          <span>Back to Speakers</span>
        </Link>
      </div>
    </section>
  )
}

SpeakerTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  position: PropTypes.string,
  photo: PropTypes.object,
  books: PropTypes.array,
  socialMedia: PropTypes.array,
  events: PropTypes.array,
  editionSlug: PropTypes.string,
}

const Speaker = ({ data }) => {
  const { speaker } = data

  let events = null
  if (speaker.events) events = speaker.events

  events.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));

  const bio = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(speaker.bio)
    .toString()

  const meta = [
    { property: 'og:title', content: speaker.name },
    { property: 'og:description', content: truncate(speaker.bio, { 'length': 200, 'omission': '\u2026' }) },
  ]

  if (speaker.photo && speaker.photo.name) {
    meta.push({ property: 'og:image', content: process.env.GATSBY_WEBSITE_URL + speaker.photo.name })
    meta.push({ name: 'twitter:image:alt', content: speaker.name })
  }

  const pathName = globalHistory.location.pathname
  const edition = pathName.split('/')[1]

  return (
    <Layout>
      <Helmet
        title={`${speaker.name} | ${speaker.edition ? speaker.edition.name : 'Fluxible'} Speaker`}
        meta={meta}
      />
      <SpeakerTemplate
        content={bio}
        contentComponent={HTMLContent}
        title={speaker.name}
        position={speaker.jobPosition}
        photo={speaker.photo}
        books={speaker.books}
        socialMedia={speaker.socialMedia}
        events={events}
        editionSlug={edition}
      />
    </Layout>
  )
}

Speaker.propTypes = {
  data: PropTypes.shape({
    speaker: PropTypes.object,
  }),
}

export default Speaker

export const pageQuery = graphql`
  query CmsSpeakerByID($id: String!) {
    speaker: fluxibleSpeakers(id: { eq: $id }) {
      id
      name
      jobPosition
      bio
      photo {
        name
        altText
        contentType
      }
      books {
        url
        title
      }
      socialMedia {
        type
        value
      }
      edition{
        name
      }
      events {
        name
        startDate
        endDate
        location {
          name
          fields {
            slug
          }
        }
        eventType{
          name
          visible
        }
        fields {
          slug
        }
      }
    }
  }
`
